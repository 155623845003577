// Ahladang Team Licence

@import "../variables";

.loading-container,
.loading {
	height: 100px;
	position: relative;
	width: 100px;
	border-radius: 100%;
}

.loading-container {
	margin: 40px auto;

	.loading {
		border: 2px solid transparent;
		border-color: transparent $brand-color transparent $brand-color;
		animation: rotate-loading 1.5s linear 0s infinite normal;
		transform-origin: 50% 50%;
	}

	#loading-text {
		animation: loading-text-opacity 2s linear 0s infinite normal;
		font-size: 15px;
		font-weight: bold;
		margin-top: 45px;
		opacity: 0;
		position: absolute;
		text-align: center;
		text-transform: uppercase;
		top: 0;
		width: 100px;
		color: $brand-color;
	}

	&:hover .loading {
		border-color: transparent #e45635 transparent #e45635;
	}

	&:hover .loading,
	& .loading {
		transition: all 0.5s ease-in-out;
	}
}

/***************
*/

@keyframes rotate-loading {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes loading-text-opacity {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
