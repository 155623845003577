// Ahladang Team Licence

@import "../variables";

.load-fail-container,
.load-fail {
	height: 100px;
	position: relative;
	width: 100px;
	border-radius: 100%;
}

.load-fail-container {
	cursor: pointer;
	margin: 40px auto;

	.load-fail {
		border: 2px solid transparent;
		border-color: transparent $danger-color transparent $danger-color;
		transform-origin: 50% 50%;
		animation: load-fail-text-opacity 3s linear 0s infinite normal;
	}

	.load-fail-text {
		line-height: normal;
		font-size: 10px;
		font-weight: bold;
		margin-top: 35px;
		position: absolute;
		text-align: center;
		text-transform: uppercase;
		top: 0;
		width: 100px;
		span {
			color: $danger-color;
		}

		.try {
			margin-top: 7px;
			font-size: 8px;
			color: $brand-color;
		}
	}

	&.brand-bg {
		.load-fail {
			border-color: transparent $danger-color transparent $danger-color !important;
		}

		.load-fail-text {
			.try {
				color: $danger-color !important;
			}
		}
	}

	&.load-fail {
		transition: all 0.5s ease-in-out;
	}
}

/***************
*/

@keyframes load-fail-text-opacity {
	0% {
		opacity: 0;
	}

	20% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
