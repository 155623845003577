@import url(https://fonts.googleapis.com/css?family=Battambang);
@import url(https://fonts.googleapis.com/css?family=Hanuman);
#header{padding:0;color:#f5f5f5}#header .info{background-color:rgba(41,145,214,0.8);background-size:contain;background-image:url(/react-app-build/static/media/platform.cfa1e303.png);background-position:calc(50% - 150px) -40px;background-repeat:no-repeat;padding-bottom:10px;border-bottom:1px solid #2991d6;padding:10px}#header .info .sub-title{margin-top:70px;padding-top:10px}#header .info .tsparticles{position:absolute;top:0;left:0;width:100%;transition:all}#header .info .logo{text-align:center;text-shadow:-1px -1px 0px rgba(255,255,255,0.3),1px 1px 0px rgba(0,0,0,0.8);font-weight:350;font-size:40px}@media only screen and (max-width: 600px){#header .info .description{display:none}#header .info{background-position:left center}}#header .buttons-container{max-width:1080px;margin:0 auto;padding:5px;padding-top:5px}#header .buttons-container .go-account{text-decoration:underline;color:#2991d6}#header .buttons-container .post-opps{float:right;font-size:22px;padding:5px;text-shadow:-1px -1px 0px rgba(255,255,255,0.3),1px 1px 0px rgba(0,0,0,0.8);box-shadow:0 2.8px 2.2px rgba(0,0,0,0.034),0 6.7px 5.3px rgba(0,0,0,0.048),0 12.5px 10px rgba(0,0,0,0.06),0 22.3px 17.9px rgba(0,0,0,0.072),0 41.8px 33.4px rgba(0,0,0,0.086),0 100px 80px rgba(0,0,0,0.12)}#header .buttons-container .post-opps-google-form{float:right;margin-right:10px}#header .buttons-container .post-opps-google-form i{color:white}

#body{padding:5px}#body .title{margin:10px 0}#body .job-list-container{background-color:#d2e2ff54}#body .job-list-container .job-list{padding:5px}@media only screen and (max-width: 700px){.body{margin:0}}

.job-container{position:relative}.job-container .background-html{position:absolute;top:0;left:0;width:100%;height:100%;z-index:-1;overflow:hidden}.job-container .job{text-decoration:none;border-bottom:1px solid rgba(41,145,214,0.2)}.job-container .job:hover{background-color:rgba(0,174,255,0.137)}.job-container .job .apply-button{visibility:hidden}.job-container .job:hover .apply-button{visibility:visible !important}.job-container .job .verified{text-align:center;width:30px;height:30px;background-color:rgba(28,210,255,0.253);border-radius:50%;padding:0;font-size:20px}.job-container .job .verified i{color:#5f5f5f}.job-container .job .pin{font-size:25px}.job-container .job .location i{padding-right:5px}.job-container .job .com-no-logo{width:50px;font-size:40px}

.loading-container,.loading{height:100px;position:relative;width:100px;border-radius:100%}.loading-container{margin:40px auto}.loading-container .loading{border:2px solid transparent;border-color:transparent #2991d6 transparent #2991d6;-webkit-animation:rotate-loading 1.5s linear 0s infinite normal;animation:rotate-loading 1.5s linear 0s infinite normal;transform-origin:50% 50%}.loading-container #loading-text{-webkit-animation:loading-text-opacity 2s linear 0s infinite normal;animation:loading-text-opacity 2s linear 0s infinite normal;font-size:15px;font-weight:bold;margin-top:45px;opacity:0;position:absolute;text-align:center;text-transform:uppercase;top:0;width:100px;color:#2991d6}.loading-container:hover .loading{border-color:transparent #e45635 transparent #e45635}.loading-container:hover .loading,.loading-container .loading{transition:all 0.5s ease-in-out}@-webkit-keyframes rotate-loading{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@keyframes rotate-loading{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}@-webkit-keyframes loading-text-opacity{0%{opacity:0}20%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes loading-text-opacity{0%{opacity:0}20%{opacity:0}50%{opacity:1}100%{opacity:0}}

.load-fail-container,.load-fail{height:100px;position:relative;width:100px;border-radius:100%}.load-fail-container{cursor:pointer;margin:40px auto}.load-fail-container .load-fail{border:2px solid transparent;border-color:transparent #dc3545 transparent #dc3545;transform-origin:50% 50%;-webkit-animation:load-fail-text-opacity 3s linear 0s infinite normal;animation:load-fail-text-opacity 3s linear 0s infinite normal}.load-fail-container .load-fail-text{line-height:normal;font-size:10px;font-weight:bold;margin-top:35px;position:absolute;text-align:center;text-transform:uppercase;top:0;width:100px}.load-fail-container .load-fail-text span{color:#dc3545}.load-fail-container .load-fail-text .try{margin-top:7px;font-size:8px;color:#2991d6}.load-fail-container.brand-bg .load-fail{border-color:transparent #dc3545 transparent #dc3545 !important}.load-fail-container.brand-bg .load-fail-text .try{color:#dc3545 !important}.load-fail-container.load-fail{transition:all 0.5s ease-in-out}@-webkit-keyframes load-fail-text-opacity{0%{opacity:0}20%{opacity:0}50%{opacity:1}100%{opacity:0}}@keyframes load-fail-text-opacity{0%{opacity:0}20%{opacity:0}50%{opacity:1}100%{opacity:0}}

.pagination{width:100%;padding:5px;text-align:center}.pagination .buttons-container .ald-btn,.pagination .buttons-container div{display:inline-block;min-width:50px;margin:0 1px}

#lang-option{height:100%;background:white}#lang-option .body{margin-top:20px}#lang-option .body .language .label{margin-bottom:5px}#lang-option .body .language div{display:inline-block}#lang-option .body .language .language-option{margin-left:10px;color:#007bff;font-size:24px;border:1px solid #007bff;background:transparent;border-radius:0.25rem;box-sizing:border-box;cursor:pointer}#lang-option .body .language .language-option.selected{background:#007bdd;color:white}#lang-option .body .language .language-option span{margin-left:3px}#lang-option .body .go-quizzing{margin-top:30px}

#footer{width:100%;padding:20px 5px;border-top:1px solid rgba(255,255,255,0.185);background-color:rgba(41,145,214,0.1)}#footer nav{text-align:center}#footer nav a{text-decoration:none;padding:5px}

html,body{width:100%;height:100%;min-height:100%;margin:0;padding:0}*{color:#383838}.brand-color{color:#2991d6}body{font-family:"Battambang", "Hanuman", Arial, serif !important;font-size:14px;background-color:#f5f5f5;background-image:url(/react-app-build/static/media/pattern-clear1.4cba7314.png);background-position:0 0;background-size:150px;background-repeat:repeat}::-webkit-scrollbar{width:9px;height:9px}::-webkit-scrollbar-track{background:#f5f5f5}::-webkit-scrollbar-thumb{background:#2174ab}#root{margin:0 auto;padding:0;width:100%;height:100%;display:flex;flex-direction:column}#root #header{border-bottom:1px solid rgba(255,255,255,0.185)}#root #body{max-width:1080px;margin:0 auto}#root #header,#root #body,#root #footer{width:100%}#root #footer{margin:0;margin-top:auto}.fade-in{-webkit-animation:fadein 0.5s ease-in;animation:fadein 0.5s ease-in}@-webkit-keyframes fadein{from{opacity:0}to{opacity:1}}@keyframes fadein{from{opacity:0}to{opacity:1}}.ald-btn{color:#f5f5f5;font-size:1em;border-radius:5px;background-color:#bebebe;padding:2px;display:inline-block;text-align:center}.ald-btn:hover{opacity:0.9}.ald-btn.disabled{background-color:rgba(190,190,190,0.3);pointer-events:none;cursor:default}.ald-color-primary{color:#2991d6 !important}.ald-color-secondary{color:#bebebe !important}.ald-color-success{color:#28a745 !important}.ald-color-danger{color:#dc3545 !important}.ald-color-warning{color:#ffc107 !important}.ald-color-info{color:#17a2b8 !important}.ald-bg-primary{background-color:#2991d6 !important}.ald-bg-secondary{background-color:#bebebe !important}.ald-bg-success{background-color:#28a745 !important}.ald-bg-danger{background-color:#dc3545 !important}.ald-bg-warning{background-color:#ffc107 !important}.ald-bg-info{background-color:#17a2b8 !important}

